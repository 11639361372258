// extracted by mini-css-extract-plugin
export var bWarrper = "index-module--bWarrper--1x+S6";
export var banner = "index-module--banner--zMUU4";
export var bannerWrapper = "index-module--bannerWrapper--pJm1p";
export var btns = "index-module--btns--Wlpyb";
export var cardCotent = "index-module--cardCotent--shmx0";
export var cardDesc = "index-module--cardDesc--I3e8y";
export var cardTitle = "index-module--cardTitle--Hew1y";
export var cardWrapper = "index-module--cardWrapper--o99re";
export var healthCareRoot = "index-module--healthCareRoot--1rZVD";
export var imgWrapper = "index-module--imgWrapper--HqmiD";
export var part3List = "index-module--part3List--olzMs";
export var partContent = "index-module--partContent--aMhkA";
export var wWarrper = "index-module--wWarrper--aWSCi";