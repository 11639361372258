import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Banner,
  Seo,
  SummaryTitle,
  TabSwitcher,
  Button,
  ScanCodeConsultButton,
  ImagePreview,
  MoreResources,
  PageBottomCard,
  SwiperCom,
} from 'components/index'
import classNames from 'classnames'

import * as styles from './index.module.less'
import ProductCards from 'components/HealthCom/ProductCards'

const Part3Item = ({ img, title, desc }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.imgWrapper}>
        <img src={img?.publicURL ?? img} alt="" />
      </div>
      <div className={styles.cardCotent}>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardDesc}>{desc}</div>
      </div>
    </div>
  )
}

const HealthCare = (props) => {
  const { data } = props
  const { healthCareYaml } = data
  const { part1, part2, part3, part4, part5 } = healthCareYaml

  return (
    <Layout {...props}>
      <Seo
        title="医疗服务行业方案 | 医疗数字化解决方案- 神策数据解决方案"
        description="神策数据提供医疗行业数字化解决方案，助力企业整合多端数据建立完整的用户画像系统，通过精细化营销加强数字化健康管理，构建医疗服务行业数字化营销与运营平台，打造智慧医疗。"
        keywords="医疗服务,数字化医疗,医疗数字化营销,智慧医疗,数字健康"
        saTitle="官网 - 产品（行业解决方案）-医疗服务"
      />
      <div className={styles.healthCareRoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={part1?.title}
            desc={part1?.desc}
            buttons={part1?.buttons}
            // img={part1.banner?.publicURL ?? part1.banner}
            className={classNames(styles.banner)}
            h1tag={true}
          />
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="专属医疗服务数字化营销与运营平台" desc="构建营销数据闭环，赋能持续增长" />
          <div className={styles.partContent}>
            <TabSwitcher data={part2} />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="为什么选择神策" />
          <div className={`${styles.part3List} flex justify-center`}>
            {part3.map((item) => (
              <Part3Item {...item} key={item?.title} />
            ))}
          </div>
          <div className="flex justify-center md:mt-[30px] mt-[3rem] ">
            <Button btnType="primary" href="/doPharmMed">
              体验 Demo
            </Button>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="医疗服务行业解决方案全景图" desc="医生、患者双轨运营解决方案" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <SwiperCom
              nodes={
                part4.imgs?.map((imgItem) => (
                  <ImagePreview imageUrl={imgItem.img?.publicURL ?? imgItem.img} alt={part4?.alt || ''} />
                )) || []
              }
              className="text-center"
              isAutoplay={true}
            />
          </div>
          {part4?.qrCode && (
            <div className="flex justify-center md:mt-[30px] mt-[3rem] ">
              <ScanCodeConsultButton
                buttonText="扫码咨询方案顾问"
                imgBottomText="扫码咨询方案顾问"
                qrcode={part4.qrCode?.publicURL ?? part4.qrCode}
              />
            </div>
          )}
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="更多干货" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <MoreResources data={part5} />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="了解神策产品" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <ProductCards />
          </div>
        </div>
        <div className="pt-[6rem] md:pt-[0px] bg-[#f9fafc]">
          <PageBottomCard
            title="开启大数据分析与营销科技之旅！"
            desc="立即注册，和神策数据专家一起探讨数字化！"
            leftButtonText="体验 Demo"
            leftButtonHref="/doPharmMed"
            rightButtonText="预约演示"
            rightButtonHref="/form/parade.html"
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    healthCareYaml {
      part1 {
        title
        desc
        banner {
          publicURL
        }
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      part2 {
        title
        sortTitle
        content {
          img {
            publicURL
          }
          alt
          introduction1
          buttons {
            btnType
            href
            text
          }
          introductionList {
            content
          }
        }
      }
      part3 {
        title
        desc
        img {
          publicURL
        }
      }
      part4 {
        imgs {
          img {
            publicURL
          }
          alt
        }
        qrCode {
          publicURL
        }
      }
      part5 {
        title
        tip
        url
        img {
          publicURL
        }
        alt
      }
    }
  }
`

export default HealthCare
